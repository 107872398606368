@import 'scss/main.scss';

.CourtFloor {
  // props
  height: mToPx(calc($FloorHeight / 2));
  width: mToPx($FloorWidth);
  max-width: calc(100vw - $RosterWidth);
  background-image: url(../../../assets/basketball_floor.png);
  background-repeat: repeat;
  background-position: center;
  background-size: 250px;
  position: relative;
}

.Court {
  // variables
  $CourtHeight: 18; // m
  $CourtWidth: 9; // m
  position: relative;

  // props
  height: mToPx(calc($CourtHeight / 2));
  width: mToPx($CourtWidth);
  border: $courtLine;
}

.CourtFloorMenu {
  position: relative;

  .CourtFloorMenuButton {
    position: absolute;
    border: none;
    border: 1px solid $mikasa-blue;
    background-color: $white-into-mikasa-blue;
    color: $mikasa-blue;
    font-family: $font-family;
    font-weight: 600;
    top: -21px;

    &:disabled {
      color: $white;
      background-color: $white-into-mikasa-blue;
      border: 1px solid $white-into-mikasa-blue;
    }
  }

  .BackwardButton {
    left: 0;
  }

  .ForwardButton {
    right:0;
  }
}

.FrontCourt {
  border-bottom: $courtLine;
}

.FrontCourtZone {
  // variables
  $FrontCourtZoneHeight: 3; // m
  $FrontCourtZoneWidth: 3; // m

  // props
  height: mToPx($FrontCourtZoneHeight);
  width: mToPx($FrontCourtZoneWidth);
}

.BackCourtZone {
  // variables
  $BackCourtZoneHeight: 6; // m
  $BackCourtZoneWidth: 3; // m

  // props
  height: mToPx($BackCourtZoneHeight);
  width: mToPx($BackCourtZoneWidth);
}

.FreeZone {
  // variables
  $FreeZoneHeight: 12; // m
  $FreeZoneWidth: 3; // m

  // props
  height: mToPx($FreeZoneHeight);
  width: mToPx($FreeZoneWidth);
}

.ServingArea {
  // variables
  $ServingAreaHeight: 3; // m
  $ServingAreaWidth: 9; // m

  // props
  height: mToPx($ServingAreaHeight) - $courtLineThickness * 2; // correction for border
  width: mToPx($ServingAreaWidth);
}
