@import 'scss/main.scss';

.Player {
  // variables
  $PlayerHeight: 1; // m
  $PlayerWidth: 1; // m

  // props
  height: mToPx($PlayerHeight);
  width: mToPx($PlayerWidth);
  border-radius: 100%;
  background-color: $mikasa-blue;
  position: absolute;

  .NameTag {
    color: $black;
    background-color: $mikasa-yellow;
    font-family: $font-family;
    font-size: $xs-font-size;
    border: 1px solid $black;
  }

  .Position {
    color: $white;
    font-family: $font-family;
    font-weight: 600;
  }
}
