/*-- Colors -- */
$white: #ffffff;
$black: #000000;
$transparent: rgba(0,0,0,0);
$dim-transparent: rgba(0,0,0,0.6);
$mikasa-yellow: #efc815;
$mikasa-blue: #273f8f;
$molten-green: #0da082;
$molten-red: #d24248;
$white-into-mikasa-blue: #bec6e0;
/*-- Typeography --*/
    /*-- Fonts --*/
    $font-family: 'Helvetica Neue', sans-serif;
    /*-- Sizing --*/
    $xs-font-size: 14px;
    $sm-font-size: 20px;
    $lg-font-size: 40px;
    $xl-font-size: 80px;
    /*-- Color --*/
    $placeholder-text-color: $white-into-mikasa-blue;
/*-- Borders --*/
    /*-- Color --*/
    $input-border-color: $white-into-mikasa-blue;
    /*-- Radius --*/
    $xs-corner: 3px;
/*-- Spacing --*/
    /*-- Padding --*/
    $xs-padding: 3px;
    $sm-padding: 5px;
    $lg-padding: 10px;
    /*-- Margin --*/
    $xs-margin: 3px;
    $sm-margin: 5px;
    $md-margin: 7px;
    $lg-margin: 10px;
    $xl-margin: 15px;
/*-- Court --*/
    /*-- Measurements --*/
    $FloorHeight: 24; // m
    $FloorWidth: 15; // m
    $mToPxConversionConstant: 50px;
    $courtLineThickness: calc($mToPxConversionConstant / 10);
    /*-- Borders --*/
    $courtLine: $courtLineThickness solid $white;
/*-- Roster --*/
    /*-- Measurements --*/
    $RosterWidth: 200px;
/*-- Modal --*/
    /*-- Measurements --*/
    $ModalContentHeight: 450px;
    $ModalContentWidth: 450px;
