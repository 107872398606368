@import 'scss/main.scss';

$RosterPlayerHeight: 30px;
$RosterPlayerWidth: $RosterWidth - 30px;
$PlayerStatusWidth: 20px;
$PlayerPositionWidth: $RosterPlayerHeight;
$PlayerNameWidth: $RosterPlayerWidth - $PlayerStatusWidth - $PlayerPositionWidth;
$RosterPlayerBorderWidth: 3px;


.Roster {
  height: mToPx(calc($FloorHeight / 2));
  width: $RosterWidth;
  background-color: $mikasa-blue;
  overflow-y: scroll;

  .RosterTitle {
    font-family: $font-family;
    font-weight: 600;
    color: $white;
    line-height: $RosterPlayerHeight;
  }

  .RosterPlayer {
    height: $RosterPlayerHeight + $xl-margin;
    cursor: move;
  
    .innerContainer {
      height: $RosterPlayerHeight;
      width: $RosterPlayerWidth;
      border: $RosterPlayerBorderWidth solid $white;
      background-color: $mikasa-yellow;


      .PlayerStatus {
        height: $RosterPlayerHeight;
        width: $PlayerStatusWidth;
        background-color: $molten-green;
      }

      .PlayerName {
        width: $PlayerNameWidth;
        line-height: $RosterPlayerHeight;
        
        span {
          $playerNamePadding: $sm-padding;
          color: $black;
          font-family: $font-family;
          font-weight: 600;
          width: $PlayerNameWidth - $playerNamePadding * 2;
          text-align: center;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          padding-right: $playerNamePadding;
          padding-left: $playerNamePadding;
        }
      }

      .PlayerPosition {
        height: $RosterPlayerHeight;
        width: $PlayerPositionWidth;
        background-color: $mikasa-blue;

        span {
          color: $white;
          font-family: $font-family;
          font-weight: 600;
          line-height: $RosterPlayerHeight;
        }
      }
    }
  }
}

