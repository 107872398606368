@import 'scss/main.scss';

.MultiSelect {
  font-family: $font-family;
  font-size: $xs-font-size;

  .selected {
    .option {
      color: $mikasa-blue;
      cursor: pointer;
    }
  }

  .unselected {
    .option {
      color: $white-into-mikasa-blue;
      cursor: pointer;
  
      &:hover {
        color: $mikasa-yellow;
      }
    }
  }
}