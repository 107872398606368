@import 'scss/main.scss';

.AddButton {
  $AddButtonDiameter: 35px;
  $AnimationDuration: 0.3s;

  height: $AddButtonDiameter;
  width: $AddButtonDiameter;
  background-color: $transparent;
  border: 0px;
  position: relative;
  border-radius: 100%;
  outline: none;
  @include ease-in-animation(0.3s);

  .line1 {
    width: 25px;
		height: 2px;
		background-color: $white;
		display: inline-block;
		position: absolute;
		top: calc((100% - 2px) / 2);
    left: calc((100% - 25px) / 2);
    @include ease-in-animation(0.3s);
  }

  .line2 {
		width: 2px;
		height: 25px;
		background-color: $white;
		display: inline-block;
		position: absolute;
		top: calc((100% - 25px) / 2);
    left: calc((100% - 2px) / 2);
    @include ease-in-animation(0.3s);
  }
  
  &:hover {
    .line1, .line2 {
      background-color: $white-into-mikasa-blue;
    }
  }

  &:active {
    .line1, .line2 {
      @include rotate(90deg);
    }
  }
}